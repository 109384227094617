@import "../variable";

.component-topayment {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  z-index: 1000;
  width: 100%;
  text-align: right;
  height: 60px;
  display: none;
  align-items: center;
  justify-content: center;
}

.component-available-services {
  position: fixed;
  z-index: 1002;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  transform: translateY(150%);
  transition: 0.3s ease-in-out;
  overflow: hidden;

  &.show {
    transform: translateY(0%);
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.378);
  }

  &__services {
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    position: relative;
    z-index: 10;
    max-height: 100vh;
    overflow: auto;

    &__close {
      position: absolute;
      top: 10px;
      right: 20px;
    }

    &__card {
      background-color: map-get($map: $colors, $key: "dark-blue");
      border-radius: 17px;
      color: #fff;
      font-weight: bold;
      text-transform: capitalize;
      font-size: 1.3em;

      &__btn-green {
        border: none;
        background-color: map-get($map: $colors, $key: "green-base");
        color: map-get($map: $colors, $key: "dark-blue");
        border-radius: 6px;
        font-weight: bold;
        padding: 6px 17px;
      }
    }
  }
}
