$fonts: (
  "main": "sans-serif",
);

$colors: (
  "white-base": #fff,
  "grey-base": #747c91,
  "blue-base": #002df9,
  "dark-blue": #203391,
  "medium-blue": #1231BA,
  "green-base": #4cfd7e,
  "base": #3e7dda,
  "disabled-base": #90bcff,
  "base-hover": #216ad8,
  "second-base": #3dc7ce,
  "second-disabled-base": #90f5fa,
  "second-base-hover": #0eadb6,
  "bg": #ffffff,
  "bg-input": #f8faff,
  "border-inputs": #e9e9e9,
  "font-white": #fff,
  "font-black": #000,
  "border": #e7ebf6,
  "disabled-text": #aeb6cc,
);

$types: (
  "danger": #ff3434,
  "warning": #ff8534,
  "primary": #002df9,
);

$colors-rgba: (
  "white": rgba(255, 255, 255, 1),
  "back": rgba(0, 0, 0, 1),
);

$bg-colors: (
  "blue-light": #f8faff,
  "grey-blue": #cfd7ed,
  "blue-base": #002df9,
);

$font-size: (
  "min": 11.5px,
  "medium": 13px,
);

$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

$primary-color: #213291;
$second-color: #3337CE;
$three-color: #3D5CFA;
$accent-color: #05ff7d;
$gray-color: #F3F5FA;
$white-color: #FFFFFF;
