.whatsapp-float {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: transparent;
    z-index: 1000;

    button {
        border: none;
        background-color: transparent;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        svg {
            width: 60px;
            height: 60px;
        }
    }
}

.bounce {
    animation-name: bounce;
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
