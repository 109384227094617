@import "./variable";
.template-service {
  &__header {
    overflow: hidden;
    position: relative;

    &__label {
      background-color: map-get($map: $colors, $key: "dark-blue");
      width: 100%;
      color: #fff;
      padding: 10px;
      display: flex;
      justify-content: center;
      font-size: 1.5em;
      font-weight: bold;

      i {
        margin-left: 15px;
      }
    }
    img {
      width: 100%;
    }
  }

  .faq-font-size {
    font-size: 1.7vw;
    padding: 15px 55px;

    @media(max-width: 575px) {
      font-size: 1rem;
      padding: 10px;
    }
  }
}
