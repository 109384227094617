@import "../variable";

.container-address-seted {
  display: flex;
  align-items: center;
  background-color: map-get($map: $colors, $key: "bg-input");
  height: 80px;
  border: 1px solid map-get($map: $colors, $key: "border");
  padding: 0 25px;

  &.bg-white {
    background-color: #fff !important;
    border: none;
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
  }

  &__text {
    width: 80%;
    &__item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  i {
    font-size: 1.3em;
    margin-right: 10px;
  }

  img {
    width: 15px;
  }
}
