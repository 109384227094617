@import "./variable";
.container-service-template {
  &__header {
    width: 100%;
    overflow: hidden;
    position: relative;

    &__bg {
      &__image {
        width: 100%;
        max-height: 180px;
        object-fit: cover;
      }
    }

    &__title {
      position: relative;
      bottom: 0;
      background-color: map-get($map: $colors, $key: "blue-base");
      width: 100%;
      text-align: center;
      padding: 10px;
      color: #fff;
      font-weight: bold;
      font-size: 1.3em;
    }

    &__back {
      position: absolute;
      bottom: 50px;
      width: 100%;
      padding: 10px 0px;
      color: #fff;
      font-size: 1em;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
