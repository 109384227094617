@import "../variable";

.component-confirm {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100vh;
  transform: translateX(100%);
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  z-index: 1001;

  &.open {
    transform: translateX(0);
  }

  &__content {
    background-color: map-get($map: $colors, $key: "blue-base");
    color: #fff;
    top: 0px;
    position: sticky;

    &.danger {
      background-color: map-get($map: $types, $key: "danger");
    }

    &.warning {
      background-color: map-get($map: $types, $key: "warning");
    }
  }
}
