@import "../variable";

.container-card-wash {
  display: flex;
  width: 100%;
  height: 160px;
  border-radius: 17px;
  overflow: hidden;
  color: map-get($map: $colors-rgba, $key: "white");
  position: relative;
  box-shadow: 0px 0px 15px 6px map-get($map: $colors, $key: "border");
  background-color: map-get($map: $colors, $key: "dark-blue");
  max-width: 550px;

  @media (min-width: 1025px) {
    height: 200px;
  }

  @media (min-width: 1367px) {
    height: 220px;
  }

  &__image {
    width: 35%;
    height: 100%;
    background-color: map-get($map: $colors-rgba, $key: "white");
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-right: 3px solid map-get($map: $colors, $key: "green-base");

    @media (min-width: 1023px) {
      width: 40%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: map-get($map: $colors, $key: "dark-blue");
    }

    &.direction {
      border-radius: 17px;
      border-right: 4px solid map-get($map: $colors, $key: "green-base");
    }
  }

  &__image__small {
    width: 35%;
    height: 100%;
    background-color: map-get($map: $colors-rgba, $key: "white");
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-right: 3px solid map-get($map: $colors, $key: "green-base");

    @media (min-width: 1023px) {
      width: 40%;
    }

    img {
      width: 80%;
      height: 80%;
      object-fit: cover;
      background-color: map-get($map: $colors, $key: "dark-blue");
    }

    &.direction {
      border-radius: 17px;
      border-right: 4px solid map-get($map: $colors, $key: "green-base");
    }
  }

  &__text {
    width: 65%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    padding: 10px 0 10px 20px;
    background-color: map-get($map: $colors, $key: "dark-blue");

    @media (min-width: 1023px) {
      width: 60%;
    }

    &__title {
      font-size: 1em;

      @media (min-width: 1024px) {
        font-size: 1.2em;
      }

      &:nth-child(3) {
        text-decoration: underline;
      }
    }

    &__price {
      font-size: 1.1em;

      @media (min-width: 390px) {
        font-size: 1.1em;
      }

      @media (min-width: 991px) {
        font-size: 0.9em;
      }

      @media (min-width: 1100px) {
        font-size: 1.3em;
      }
    }

    &__details {
      font-size: 0.8em;

      @media (min-width: 390px) {
        font-size: 1.2em;
      }

      @media (min-width: 991px) {
        font-size: 0.9em;
      }

      @media (min-width: 1100px) {
        font-size: 0.9em;
      }
    }

    &__subtitle {
      font-size: 0.9em;
      font-weight: bold;
      cursor: pointer;

      &.direction {
        background-color: map-get($map: $colors, $key: "green-base");
        color: map-get($map: $colors, $key: "dark-blue");
        padding: 6px 20px;
        border-radius: 7px;
        font-size: 0.8em;

        @media (min-width: 1024px) {
          font-size: 1.3em;
        }
      }
    }
  }
}
