@import "../../styles/variable";
.card-home-lub {
    width: 300px;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    border: 1px solid #d8dcff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    filter: grayscale(.5);

    &:hover {
        filter: grayscale(0);
    }

    &__disabled {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.796);
        z-index: 1000;
    }

    &__image {
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 90%;
            height: 90%;
        }
    }

    &__content {
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: map-get($map: $colors, $key: "dark-blue");
        border-radius: 30px 30px 0 0;
        z-index: 100;
        transform-style: preserve-3d;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding: 20px 30px;

        &::after {
            position: absolute;
            content: "";
            background-color: map-get($map: $colors, $key: "green-base");
            width: 300px;
            height: 230px;
            top: -15px;
            border-radius: 30px;
            transform: translateZ(-1px);
            left: 0;
        }
    }
}

.card-home-lub.recommended-border {
    border: 4px solid map-get($map: $colors, $key: "dark-blue");
    filter: grayscale(0) !important;
}