@import "../variable";

.container-card {
  display: flex;
  width: 100%;
  border-radius: 17px;
  overflow: hidden;
  background-color: map-get($map: $colors, $key: "dark-blue");
  color: map-get($map: $colors-rgba, $key: "white");
  position: relative;

  @media(max-width: 576px) {
    font-size: 3.6vw;
  }

  &__disabled {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.796);
  }

  &__image {
    width: 125px;
    height: 125px;
    background-color: map-get($map: $colors-rgba, $key: "white");

    @media (max-width: 340px) {
      width: 90px;
      height: 110px;
    }
  }

  &__text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 0 20px;

    &__title {
      font-size: 1.2em;
      font-weight: bold;

      @media (min-width: 1199px) {
        font-size: 1.4em;
      }
    }

    &__subtitle {
      font-size: 0.9em;
      font-weight: bold !important;
      cursor: pointer;
      color: #fff !important;
      text-align: left !important;
      padding: 0 !important;

      &.direction {
        background-color: map-get($map: $colors, $key: "green-base");
        color: map-get($map: $colors, $key: "dark-blue") !important;
        border-radius: 6px;
        font-size: 0.8em;
        text-decoration: none;
        padding: 5px 20px !important;

        @media (min-width: 576px) {
          font-size: 1.2em;
          padding: 6px 20px;
        }
      }
    }
  }
}
