.multipleservices-popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 5000;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
    overflow: auto;
    font-size: 0.8rem;
    &__popup {
        font-family: "Norms", sans-serif;
        max-width: 420px;
        width: fit-content;
        margin: 30px auto;

        @media (max-width: 440px) {
            max-width: 90%;
        }

        &__wrapper {
            background-color: #132fbc;
            border-radius: 20px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            &__header {
                padding: 1.5rem 40px;
                position: relative;
                background: transparent;
                color: #fff;

                button {
                    position: absolute;
                    right: 15px;
                    top: 5px;
                    background-color: transparent;
                    font-size: 1.3rem;
                }

                h2 {
                    margin-bottom: 0;
                    letter-spacing: 0.05rem;
                    font-size: 2.4rem;
                    line-height: 1;
                    color: #fff;
                    text-shadow: 0 2px 5px rgb(0 0 0 / 20%);

                    @media (max-width: 440px) {
                        font-size: 2rem;
                    }

                    @media (max-width: 400px) {
                        font-size: 1.8rem;
                    }

                    @media (max-width: 369px) {
                        font-size: 1.5rem;
                    }

                    @media (max-width: 325px) {
                        font-size: 1.3rem;
                    }
                }

                hr {
                    background: #00ff7d;
                    width: 100% !important;
                    height: 12px !important;
                    border-radius: 30px !important;
                    margin: -12px -3px 0 -3px !important;
                    border: 0 !important;
                    opacity: 1 !important;

                    @media (max-width: 400px) {
                        height: 9px !important;
                        margin: -9px -3px 0 -3px !important;
                    }

                    @media (max-width: 325px) {
                        height: 7px !important;
                        margin: -6px -3px 0 -3px !important;
                    }
                }
            }

            &__body {
                background-color: #fff;
                border-radius: 20px;
                padding: 2rem 40px;
                color: #213291;

                ul {
                    margin: 0 0 30px 0;
                    padding: 0;
                }
                ul li {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    list-style-type: none;
                }
                ul li img {
                    width: 35px;
                    margin-right: 20px;
                    display: block;
                }

                ul li h5 {
                    margin-bottom: 0;
                    font-size: 0.8rem;
                }

                h4 {
                    font-size: 1.1rem;
                }

                p {
                    margin-bottom: 30px;
                }

                .btn-clear {
                    background-color: transparent;
                }

                button {
                    background: #00ff7d;
                    padding: 0.5rem 1rem;
                    display: block;
                    border-radius: 50px;
                    text-align: center;
                    color: #213291;
                    transition: all 0.15s ease-in-out;
                    -webkit-transition: all 0.15s ease-in-out;
                    -moz-transition: all 0.15s ease-in-out;
                    width: 100%;
                }
            }
        }
    }
}
