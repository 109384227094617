.cookie-consent {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    position: fixed;
    z-index: 2000;
    padding: 20px;
    bottom: 30px;
    right: 30px;
    color: #3b3e4a;
    max-width: 395px;

    @media (max-width: 450px) {
        max-width: 100%;
        margin: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #dfdfdf;
        border-radius: 10px 10px 0 0;
    }

    &__header {
        font-weight: bold;
    }

    &__msg-wrap {
        p {
            font-size: 15px;
            padding: 0;
        }
        a {
            color: #115cfa !important;
        }
    }

    &__configs {
        button {
            text-transform: uppercase;
            font-size: 13px;
            display: flex;
            align-items: center;
            font-weight: 300;
        }
    }

    &__options {
        text-transform: uppercase;
        font-size: 12px;
        overflow: hidden;
        transition: 0.3s ease-out;
        max-height: 100px;
        transform-origin: top;

        input[type="checkbox"] {
            -webkit-appearance: auto;
            -moz-appearance: auto;
            appearance: auto;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeSpeed;
            width: 14px;
            height: 14px;
            display: block;
            float: left;
            position: relative;
            outline: none;
            border: none;
        }
        input[type="checkbox"]::after {
            content: "";
            vertical-align: middle;
            text-align: center;
            line-height: 13px;
            position: absolute;
            cursor: pointer;
            height: 14px;
            width: 14px;
            left: 0;
            top: 0;
            font-size: 10px;
            background: #d3d3d3;
        }
        input[type="checkbox"]:checked:after {
            background: #d3d3d3;
            content: "\2714";
            color: #808080;
        }
    }

    &__options.hide {
        max-height: 0;
    }

    &__actions {
        .btn-ok {
            color: #ffffff;
            background: #115cfa;
            border: 1px solid #115cfa;
        }
        .btn-cancel {
            color: #115cfa;
            border: 1px solid #115cfa;
            background: #ffffff;
        }

        button {
            -webkit-box-flex: 1;
            -ms-flex-positive: 1;
            flex-grow: 1;
            padding: 0 7px;
            margin: 0 5px 10px 5px;
            border-radius: 20px;
            cursor: pointer;
            white-space: nowrap;
            min-width: 130px;
            line-height: 36px;
            border: none;
            font-family: inherit;
            font-size: 16px;
            -webkit-transition: -webkit-box-shadow 0.3s;
            -o-transition: box-shadow 0.3s;
            transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
            font-weight: 300;
        }
    }
}

.cookie-consent.hide {
    display: none;
}
