@import "../variable";

.container-keroseneinfo {
  min-height: 60vh;
  color: #000;
  &__info {
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
  }
}

.container-pricing {
  span {
    font-weight: bold;
    color: map-get($map: $colors, $key: "blue-base");
    &:first-child {
      color: map-get($map: $colors, $key: "green-base");
    }
  }
}
