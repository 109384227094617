@import "../variable";

.component-cupon {
  border: 1px solid map-get($map: $colors, $key: "border");
  border-radius: 5px;
  overflow: hidden;
  img {
    width: 7%;
    margin-left: 5%;
  }
  input {
    border: none;
    background-color: transparent;
    height: 50px;
    width: 60%;
  }

  button {
    background-color: map-get($map: $colors, $key: "blue-base");
    color: #fff;
    border: none;
    width: 20%;
    font-weight: bold;

    &:disabled {
      background-color: #e3e3e3;
    }
  }
}
