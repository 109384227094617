.btn-default {
  background-color: map-get($map: $colors, $key: "dark-blue");
  color: map-get($map: $colors-rgba, $key: "white");
  font-weight: bold;
  padding: 5px 12px;
  font-size: 0.8em;
  border: none;
  border-radius: 4px;
  transition: 0.2s ease;

  &:focus {
    color: #fff;
  }

  &:active {
    background-color: #010a8a;
    color: #fff;
  }

  &:hover {
    color: inherit;
    filter: saturate(90%);
  }

  &:disabled {
    background-color: map-get($map: $colors, $key: "disabled-text");
  }
}

.btn-option {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  padding: 5px 12px;
  font-size: 0.8em;
  border-radius: 4px;
  transition: 0.2s ease;
  border: 1px solid #c5c5c5;
}


.btn-white {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 7px 20px 7px 10px;
  border-radius: 5px;
  font-weight: bold;
  display: inline-flex;
  justify-content: space-around;

  i {
    color: map-get($map: $colors, $key: "blue-base");
    background-color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 1px;
  }
}

.btn-green {
  background-color: map-get($map: $colors, $key: "green-base");
  color: map-get($map: $colors, $key: "dark-blue");
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.8em;
}

.btn-back-default {
  position: relative;
  padding: 10px 30px;
  font-size: 1em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: transparent;

  &__icon {
    background-color: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    margin-right: 10px;
    border: 1px solid map-get($map: $colors, $key: "border");
    box-shadow: 0px 0px 6px #cfd7ed80;

    div {
      border-right: 2px solid map-get($map: $colors, $key: "blue-base");
      border-bottom: 2px solid map-get($map: $colors, $key: "blue-base");
      content: "";
      width: 90%;
      height: 90%;
      transform: rotate(135deg);
    }
  }
}

.btn-red {
  background-color: #ff3434;
  color: #fff;
}

.red-circle-cart {
  width: 15px;
  height: 15px;
  font-size: 0.7rem;
  background: red;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  position: absolute;
  right: 25%;
  border: none;
}

.btn-green-light,
.btn-green-light-small {
  background-color: map-get($map: $colors, $key: "green-base");
  color: #203391;
  font-weight: bold;
  padding: 12px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.btn-green-light-small {
  padding: 5px 15px;
}

.btn-circle-green-light {
  background-color: map-get($map: $colors, $key: "green-base");
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.btn-square-arrow-down {
  border: 1px solid map-get($map: $colors, $key: "green-base");
  padding: 0px;
  width: 25px;
  height: 25px;
}

.btn-blue-light {
  background-color: #E7E9FE;
  color: map-get($map: $colors, $key: "dark-blue");
}

.btn-dark-blue {
  background-color: map-get($map: $colors, $key: 'dark-blue');
  color: #FFF;
}

.btn-clear {
  border: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;

}

.btn-white-faq {
  box-shadow: 0px 3px 6px #00000029;
  color: map-get($map: $colors, $key: "dark-blue");
  background-color: #FFF;
  padding: 15px 30px;
  border-radius: 30px;
}