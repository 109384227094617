.menu-header {
    position: fixed;
    z-index: 2000;
    background-color: #fff;
    width: 99%;
    height: 100vh;
    top: 0;
    left: 0;
    padding: 20px;
    transition: 0.7s ease;
    transform: translateX(-150%);

    ul {
        list-style: none;
        padding: 10px;
        margin: 0;

        li {
            padding: 10px 0px;
        }
    }
}

.menu-header.show {
    transform: translateX(0%);
}