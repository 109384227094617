@font-face {
    font-family: normsLight;
    src: url("../assets/alima/fonts/TTNorms-Light.otf");
}

@font-face {
    font-family: normsMedium;
    src: url("../assets/alima/fonts/TTNorms-Medium.otf");
}

@font-face {
    font-family: normsBold;
    src: url("../assets/alima/fonts/TTNorms-Bold.otf");
}

@font-face {
    font-family: extraBold;
    src: url("../assets/alima/fonts/TTNorms-ExtraBold.otf");
}

:root {
    --font-normsLight: normsLight;
    --font-normsMedium: normsMedium;
    --font-normsBold: normsBold;
    --font-extraBold: extraBold;
}

@mixin bloc($color) {
    border-radius: 80px 80px 0px 0px;
    background-color: $color;
    padding: 0px 200px 0px 200px !important;
}

@mixin accordion($img) {
    background: url($img) !important;
    background-size: 20px !important;
    background-repeat: no-repeat !important;
}

.accordion-button.collapsed::after {
    @include accordion('../assets/alima/upArrow.svg');
}

.accordion-button:not(.collapsed)::after {
    @include accordion('../assets/alima/downArrow.svg');
}

.accordion-button:not(.collapsed) {
    background-color: $white-color !important;
    box-shadow: none !important;
}

.container-color {
    @include bloc($gray-color);
    opacity: 1;
    min-height: 600px;
}

.container-white {
    @include bloc($white-color);
    text-align: center;
    width: 100%;
}

.title {
    color: $primary-color !important;
    font-size: 35px;
    text-align: center;
    margin: 50px;
    font-family: var(--font-normsBold) !important;
}

.containerTitle {
    width: 100%;
    text-align: center;
    margin-top: 80px;
}

.cardBtn {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;
    border: 0.8px solid #d9d9d9;
    padding: 24px;
    border-radius: 8px;
}

.titlePanel {
    color: $primary-color !important;
    font-size: 17px;
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-family: var(--font-normsMedium) !important;
    font-weight: normal;
}

.topStyle {
    opacity: 1;
    background-color: $gray-color;
    padding: 0px 200px 0px 200px !important;
    min-height: 500px;
}

.styleContent {
    background-color: $gray-color;
    opacity: 1;
    min-height: 500px;
    margin-bottom: 50px;
}

.styleContentWhite {
    background-color: $white-color;
    opacity: 1;
    min-height: 500px;
}

.titleStyle {
    color: $primary-color;
    font-size: 40px;
    padding-top: 120px;
    font-family: var(--font-normsBold);
}

.subTitleStyle {
    color: $second-color;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: var(--font-normsMedium);
}

.titleClient {
    color: $primary-color;
    font-size: 35px;
    margin-top: 50px;
    text-align: left;
    font-family: var(--font-normsBold);
}
.textClient {
    text-align: left;
}

.text {
    color: $second-color;
    font-size: 12px;
    margin-top: 20px;
    padding-right: 100px;
    font-family: var(--font-normsLight);
}

.btnRight {
    padding-right: 100px;
}

.acordeon {
    margin-bottom: 24px;
    border: none !important;
    font-family: var(--font-normsBold);
}

.acordeonTitle {
    color: $primary-color;
    font-size: 20px;
    float: left;
    margin-top: 5px;
    margin-left: 20px;
    font-family: var(--font-normsMedium) !important;
    font-weight: normal;
}

.btnApp {
    background-color: $accent-color !important;
    border: none !important;
    color: $primary-color !important;
    font-size: 16px;
    width: auto;
    font-family: var(--font-normsBold) !important;
}

.btnRegister {
    background-color: $gray-color !important;
    border-color: $gray-color !important;
    color: $three-color !important;
    font-weight: bolder !important;
    margin-top: 10px;
    height: 44px;
}
.btnQuestion {
    color: $primary-color !important;
    font-size: 16px;
    height: 64px;
    text-decoration: none !important;
}
.card {
    min-height: 330px;
    padding: 20px;
    box-shadow: none;
    border: 0.8px solid rgb(217, 217, 217) !important;
    border-radius: 8px;
    background-color: $white-color;
    margin-bottom: 10px;
}

.titleCard {
    color: $primary-color !important;
    font-size: 19px;
    font-family: var(--font-normsMedium) !important;
    margin-bottom: 20px;
    line-height: 20px;
}

.contentCard {
    color: #6e7478;
    font-family: var(--font-normsLight);
    font-size: 14px;
}

.showContent {
    display: none;
}

.hideContent {
    display: block;
}

.contentSlider {
    margin: 0px;
}

.paragrapHistory {
    margin-top: 40px;
    font-size: 16px;
    font-family: var(--font-normsLight);
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .hide {
        display: none;
    }
}

@media only screen and (max-width: 1025px) {
    .container-color,
    .container-white,
    .topStyle {
        padding: 0px 30px 0px 30px !important;
        border-radius: 40px 40px 0px 0px;
    }

    .img-fluid-car {
        max-width: 100%;
        height: auto;
    }
}

/*Mobile*/
@media only screen and (max-width: 768px) {
    .acordeon {
        margin: 10px;
    }

    .acordeonTitle {
        font-size: 14px;
        margin-left: 10px;
        margin-top: 0px;
    }

    .acordeonImg {
        height: 25px;
    }

    .img-fluid-car {
        max-width: 100%;
        height: auto;
        margin-bottom: 10px;
    }
    .title {
        font-size: 30px;
        line-height: 1.2;
        margin: 30px;
    }
    .titleClient {
        font-size: 25px;
        margin-top: 40px;
        text-align: center;
    }
    .textClient {
        text-align: center;
    }
    .containerTitle {
        margin-top: 20px;
    }
    .titleStyle {
        font-size: 25px;
        padding-top: 60px;
    }

    .text,
    .btnRight {
        padding-right: 0px;
    }
    .card {
        margin: 0px 0px 10px 0px;
        padding: 10px;
        min-height: 310px;
    }
    .showContent {
        display: block;
    }

    .hideContent {
        display: none;
    }
    .contentSlider {
        margin: 25px;
    }
    .titleCard {
        font-size: 14px;
        line-height: 15px;
    }
    .contentCard {
        font-size: 12px;
    }
    .titlePanel {
        font-size: 15px;
    }
    .paragrapHistory {
        font-size: 14px;
    }
}

