.component-splash {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  background-color: #fff;
  transition: z-index 0.5s step-end, opacity 0.5s ease-in-out;

  &.show {
    z-index: 1001;
    opacity: 1;
    transition: z-index 0.5s step-start, opacity 0.5s ease-in-out;
  }
}
