.container-drawer-rl {
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  transform: translateX(0%);
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 5px 5px 30px #fff;

  &.drawer-with-header {
    height: calc(100vh - 70px);
    top: 70px;
  }

  &.right-left {
    transform: translateX(110%);
  }

  &.max-width {
    max-width: 450px;
  }
}

.container-drawer-bt {
  width: 100%;
  height: 100vh;
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  transform: translateY(0%);
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 5px 5px 30px #fff;

  &.drawer-with-header {
    height: calc(100vh - 70px);
    top: 70px;
  }

  &.bottom-top {
    transform: translateY(110%);
  }

  &.max-width {
    max-width: 450px;
  }
}
