@import "../../styles/variable";

.component-quantity-card {
    width: 100%;
    border: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    color: #fff;

    &__actions {
        background-color: transparent;
        border: none;
        min-width: 30px;
        color: map-get($map: $colors, $key: "green-base");
        font-size: 1.5rem;

        &:disabled {
            color: #FFF;
        }
    }
}
