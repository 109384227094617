@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "react-alice-carousel/lib/alice-carousel.css";
@import "./variable";
@import "./bootstrap.component.scss";
@import "./buttons";
@import "./inputs";
@import "./helperclasses";
@import "./alima";
@import "rsuite/dist/rsuite.min.css";

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_norms_pro_regular.otf");
}

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_normas_pro_bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_norms_pro_medium.otf");
  font-weight: 300;
}

body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: map-get($map: $colors, $key: "bg");
  color: #969cac;
  font-family: "Norms", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  height: auto;
}

div {
  scroll-behavior: smooth;
}

div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  background: #e2e3e5;
}

div::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 20px;
  border: 3px solid #e2e3e5;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

button:focus,
input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.adjustment {
  height: 40px;
}

.adjustment-desktop {
  height: 70px;
}

.adjustment-iphone {
  display: block;

  @media (min-width: 450px) {
    display: none;
  }
}

.row {
  .dashed {
    border: 1px dashed map-get($map: $colors, $key: "border");
  }
  .dashed:nth-child(even) {
    border-left: hidden;
  }
}

// h's
h1 {
  font-size: 2em;
  font-weight: bold;
}

h2 {
  font-size: 1.7em;
  font-weight: bold;
}

h3 {
  font-size: 1.5em;
  font-weight: bold;
}

h4 {
  font-size: 1.2em;
  font-weight: bold;
}

h5 {
  font-size: 1em;
  font-weight: bold;
}

// Alice Carousel

.alice-carousel {
  &__stage {
    &-item {
      display: inline-flex;
      justify-content: center;
    }
  }
}

.alice-carousel__dots-item {
  margin: 2px !important;
  background-color: map-get($map: $colors, $key: "grey-base") !important;
  transition: 0.3s ease;
}

.alice-carousel__dots-item.__active {
  background-color: map-get($map: $colors, $key: "green-base") !important;
  width: 17px;
  border-radius: 10px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.46);
}

.placeholder-09 {
  &::placeholder {
    font-size: 0.9rem;
    font-weight: 300;
  }
}

.rs-toast-container {
  width: 100%;
  right: 0;
  top: 70px;
}

.rs-toast.rs-toast-fade-entered {
  width: 100%;
}

.rs-copec-success,
.rs-copec-danger {
  max-width: 450px !important;
  .rs-btn-close {
    color: #fff;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.rs-copec-success-content {
  background-color: map-get($map: $types, $key: "primary");
  color: #fff;
  padding-top: 10px;

  .rs-copec-success-description {
    margin-top: 10px;
  }
}

.rs-copec-danger-content {
  background-color: map-get($map: $types, $key: "danger");
  color: #fff;
  padding-top: 10px;

  .rs-copec-danger-description {
    margin-top: 10px;
  }
}

.rs-anim-fade.rs-anim-in.rs-copec-select-select-menu.rs-picker-menu {
  z-index: 2000;

  .rs-copec-select-none {
    padding: 0 15px 5px;
  }
}
.rs-copec-select.rs-copec-select-select.rs-copec-select-default.rs-copec-select-toggle-wrapper.rs-copec-select-cleanable {
  background-color: #f8faff;
  border: 1px solid #e7ebf6;
  width: 100%;
  padding: 10px;
  font-size: 0.9em;
  color: #747c91;
  border-radius: 30px;

  .rs-picker-toggle.rs-btn.rs-btn-default {
    background-color: transparent;
  }
}

.item-canceled {
  text-decoration: line-through;
  opacity: 0.6;
}

.rs-panel-title {
  color: map-get($map: $colors, $key: "dark-blue");
  font-weight: bold;
}

.rs-copec-bordered {
  border: 1px solid #aeb6cc !important;
  border-radius: 10px;
  color: map-get($map: $colors, $key: "dark-blue");
}

.rs-panel.rs-panel-collapsible {
  border-bottom: 1px solid #aeb6cc;
  border-radius: 0px;
  &:last-child {
    border: none;
  }
}

.rs-modal-backdrop.rs-anim-in {
  backdrop-filter: blur(10px);
}

.gx-recommended-card {
  filter: grayscale(.5) !important;
}

.gx-recommended-card.recommended-border {
  border: 3px solid map-get($map: $colors, $key: "dark-blue");
  filter: grayscale(0) !important;
}
