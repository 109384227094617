@import "../variable";

.component-day-item,
.component-time-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 90px;
  min-height: 90px;
  border-radius: 8px;
  margin: 0 2px;
  border: 1px solid map-get($map: $colors, $key: "border");
  font-size: 0.8em;
  color: #000;
  padding: 10px 0;
  text-transform: capitalize;
  cursor: pointer;

  @media (min-width: 1025px) {
    height: 75px;
    font-size: 1.2em;
  }

  .component-time-item__text {
    text-align: center;
    font-size: 0.95em !important;
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
      text-align: center !important;
      font-size: 1.2em !important;
    }

    span {
      padding: 5px 0;
      font-weight: 300;
    }
  }

  .component-time-item__discount {
    color: map-get($map: $colors, $key: "blue-base");
    font-weight: bold;
    font-size: 15px;
  }

  .component-day-item__discount {
    color: map-get($map: $colors, $key: "blue-base");
    font-weight: bold;
    font-size: 13px;
    text-transform: none !important;
  }

  &.day-item-selected,
  &.time-item-selected {
    background-color: map-get($map: $colors, $key: "blue-base");
    color: #fff;
    font-weight: bold;

    .component-time-item__discount {
      color: map-get($map: $colors, $key: "green-base");
      font-weight: bold;
    }

    .component-day-item__discount {
      color: map-get($map: $colors, $key: "green-base");
      font-weight: bold;
      font-size: 13px;
      text-transform: none !important;
    }
  }

  &.day-item-disabled,
  &.time-item-disabled {
    background-color: map-get($map: $bg-colors, $key: "grey-blue");
    color: map-get($map: $colors, $key: "disabled-text");

    .component-time-item__discount, 
    .component-day-item__discount {
      color: map-get($map: $colors, $key: "disabled-text");
    }
  }
}
