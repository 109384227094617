@import "../variable";

.component-product-card {
  width: 96%;
  margin: 20px 2% 0px;
  box-shadow: 0px 0px 15px 6px map-get($map: $colors, $key: "border");
  border-radius: 10px;
  overflow: hidden;
  &__header {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    .full-image {
      max-width: none !important;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    img {
      max-width: 90%;
      max-height: 100%;
    }
  }

  &__content {
    background-color: map-get($map: $colors, $key: "dark-blue");
    color: #fff;
    padding: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 150px;

    @media (max-width: 600px) {
      min-height: 170px;
    }
  }
}
