.container-select {
  &.default {
    label {
      margin-left: 10px;
      padding: 10px 0;
      font-weight: bold;
      font-size: 0.9em;
    }
  }
}
