// textarea,
// input,
// button {
//   font-size: 16px !important;

//   &:focus {
//     box-shadow: 0 0 0 0 !important;
//     outline: 0;
//   }
// }

// textarea,
// input,
// select {
//   &::placeholder {
//     color: #cac8c8;
//   }
// }

// select {
//   &:focus {
//     font-size: 16px !important;
//     box-shadow: 0 0 0 0 !important;
//     outline: 0;
//   }
// }

// Inputs

.input-rounded {
  background-color: map-get($map: $colors, $key: "bg-input");
  border: 1px solid map-get($map: $colors, $key: "border");
  width: 100%;
  padding: 15px 10px;
  border-radius: 7px;
  font-size: 0.9em;
  color: map-get($map: $colors, $key: "grey-base");

  &.icon-left {
    padding-left: 60px;
  }

  &.black {
    color: #000 !important;
  }
}

.input-default {
  background-color: map-get($map: $colors, $key: "bg-input");
  border: 1px solid map-get($map: $colors, $key: "border");
  width: 100%;
  padding: 10px;
  font-size: 0.9em;
  color: map-get($map: $colors, $key: "grey-base");

  & + span {
    position: absolute;
    top: 35%;
    z-index: 2;
    left: 45px;
  }

  &.icon-left {
    padding-left: 60px;
    padding-right: 40px;
  }

  &.black {
    color: #000 !important;
  }
}

.input-default-editable {
  background-color: map-get($map: $colors, $key: "bg-input");
  border: 1px solid map-get($map: $colors, $key: "border");
  width: 100%;
  padding: 10px;
  font-size: 0.9em;
  color: map-get($map: $colors, $key: "grey-base");

  &:disabled {
    border: none;
    background-color: transparent;
    color: #000;
  }
}

.bg-input {
  background-color: map-get($map: $colors, $key: "bg-input");

  &.selected {
    background-color: map-get($map: $colors, $key: "blue-base");
    color: #fff !important;
    font-weight: bold;
  }
}

// Selects

.select-default {
  width: 100%;
  background: #f1f4ff;
  padding: 13px 35px;
  border-radius: 25px;
  border: 1px solid map-get($map: $colors, $key: "border");
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../assets/image/svg/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 94%;
  background-position-y: 50%;
  color: map-get($map: $colors, $key: "grey-base");
}

.select-datepicker {
  width: 100%;
  background: #fff;
  padding: 10px;
  border: none;
  color: map-get($map: $colors, $key: "blue-base");
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("../assets/image/svg/arrow_down.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 94%;
  background-position-y: 50%;
}

.textarea-default {
  background-color: map-get($map: $colors, $key: "bg-input");
  border: 1px solid map-get($map: $colors, $key: "border");
  width: 100%;
  height: 150px;
  border-radius: 10px;
  font-size: 0.9em;
  color: map-get($map: $colors, $key: "grey-base");
}

.input-transparent {
  background-color: transparent;
  border: none;
  width: 100%;

  &:disabled {
    color: #cacaca;
  }
}

input[type="radio"] {
  -webkit-appearance: none !important; /* Remove estilo padrão do Chrome */
  -moz-appearance: none !important; /* Remove estilo padrão do FireFox */
  appearance: none !important;
  background-color: transparent !important;
  border: none !important;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid map-get($map: $colors, $key: "blue-base");
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: none !important;
  background-image: url("../assets/image/svg/bullet_info.svg");
}

input[type="radio"].radio-grey-color:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
  visibility: visible;
  opacity: 1;
  border: 2px solid grey;
}

input[type="radio"].radio-grey-color:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: "";
  display: inline-block;
  visibility: visible;
  border: none !important;
  background-image: url("../assets/image/svg/bullet_info.svg");
}

.placeholder-lg {
  &::placeholder {
    font-size: 1.5rem;
  }
}
