@import "../variable";

.container-card-product {
  display: flex;
  width: 100%;
  height: 160px;
  border-radius: 17px;
  overflow: hidden;
  color: map-get($map: $colors-rgba, $key: "white");
  position: relative;
  box-shadow: 0px 0px 15px 6px map-get($map: $colors, $key: "border");

  @media (min-width: 1025px) {
    height: 200px;
  }

  @media (min-width: 1367px) {
    height: 220px;
  }

  &__image {
    width: 30%;
    height: 100%;
    border-right: 3px solid map-get($map: $colors, $key: "green-base");
    background-color: map-get($map: $colors-rgba, $key: "white");
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1023px) {
      width: 45%;
    }

    img {
      width: 80%;
    }

    &.direction {
      border-radius: 17px;
      border-right: 4px solid map-get($map: $colors, $key: "green-base");
    }
  }

  &__text {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 0 20px;
    background: map-get($map: $colors, $key: "dark-blue");

    &__title {
      font-size: 1.2em;
    }

    &__subtitle {
      font-size: 0.9em;
      font-weight: bold;
      cursor: pointer;

      &.direction {
        background-color: map-get($map: $colors, $key: "green-base");
        color: map-get($map: $colors, $key: "dark-blue");
        padding: 6px 20px;
        border-radius: 5px;
        font-size: 0.8em;
        &:hover {
          color: map-get($map: $colors, $key: "dark-blue");
        }

        @media (min-width: 1024px) {
          font-size: 1.1em;
        }
      }
    }
  }
}
