@import "./variable";
.container-template-default {
  display: flex;
  flex-direction: column;

  .mobile {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
    background-color: map-get($map: $colors-rgba, $key: "white");
    z-index: 1000;
    position: fixed;
    height: 70px;

    &__item {
      text-align: center;

      &:first-child {
        width: 50%;
      }

      &__icon {
        width: 20px;
      }

      &__logo {
        width: 90%;
        max-width: 150px;
      }
    }
  }

  &__header-desktop {
    height: 70px;
  }

  .container-footer {
    background-color: map-get($map: $colors, $key: "medium-blue");
    border-top: 9px solid map-get($map: $colors, $key: "blue-base");
    color: #fff;

    &__bottom-info {
      background-color: map-get($map: $colors, $key: "dark-blue");
      padding: 30px 5px;
    }
  }
}
