@import "../../styles/variable";
.card-lavamax {
    width: 320px;
    height: 500px;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    border: 1px solid #d8dcff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    &__disabled {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.796);
        z-index: 1000;
    }

    &__image {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 75%;
            height: 85%;
        }
        
    }

    &__image.image-full {
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        min-height: 250px;
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: map-get($map: $colors, $key: "dark-blue");
        border-radius: 30px 30px 0 0;
        z-index: 100;
        transform-style: preserve-3d;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;

        &::after {
            position: absolute;
            content: "";
            background-color: map-get($map: $colors, $key: "green-base");
            width: 320px;
            height: 230px;
            top: -22px;
            border-radius: 30px;
            transform: translateZ(-1px);
            left: 0;
        }
    }
}
